<template>
  <div>
    <ul class="showArcitle">
      <li
        v-for="(item, index) in articlesData.list"
        :key="index"
        @click="volRead(item.id, item.alone)"
      >
        <h2 style="font-family: serif; font-size: 20px; font-weight: 600">
          {{ item.title }}
        </h2>

        <div style="margin: 20px 0; font-size: 13px">
          <span style="color: #bbb">
            <i class="el-icon-date" style="margin-right: 5px"></i>
            {{ common.dateFormatter(item.insertTime) }}
          </span>
          <span style="color: #bbb; margin-left: 10px">
            <i class="el-icon-folder-remove" style="margin-right: 5px"></i>
            <span>
              {{ item.type == 0 ? "日常杂记" : "技术博文" }}
            </span>
          </span>
          <span style="color: #bbb; margin-left: 10px; margin-right: 10px">
            <i class="el-icon-thumb" style="margin-right: 5px"></i>
            {{ item.viewNum }}
          </span>
          <span v-if="item.alone == 1">
            <i class="el-icon-lock" style="color: red">加密</i>
          </span>
        </div>
      </li>
    </ul>
    <div style="text-align: center">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="articlesData.total"
        :small="this.$store.getters.isPCM ? true : false"
        :page-size="10"
        :current-page="articlesData.pageNum"
        @current-change="getData"
        style="display: inline-block"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      articlesData: {},
    };
  },
  created() {
    if (!parseInt(this.$route.params.page) > 0) {
      this.$router.replace("/index/1");
    }
    this.changePage(this.$route.params.page);
  },
  methods: {
    getData(page) {
      //点击翻页
      this.changePage(page);
      this.$router.push("/index/" + page);
    },
    changePage(page) {
      let that = this;
      this.$axios
        .get("/blog/getArticleForPage", {
          params: {
            curr: page,
            size: 10,
          },
        })
        .then(function (resp) {
          that.articlesData = resp.data;
        });
    },
    volRead(id, alone) {
      //1  是加密文章
      if (alone === 1) {
        this.$prompt("请输入密码", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputValidator: (value) => {
            //自定义校验
            if (!value) {
              return "请输入密码";
            }
          },
        }).then(
          ({ value }) => {
            this.veryRead(id, value);
          },
          () => {} //取消输入
        );
      } else {
        this.$router.push("/readBlog?flag=false&data=" + id);
      }
    },
    veryRead(aid, password) {
      let that = this;
      this.$axios
        .get("/blogApi/verifyArticlePassword", {
          params: {
            aid: aid,
            password: password,
          },
        })
        .then(function (resp) {
          if (resp.data.code === 200) {
            that.$router.push("/readBlog?flag=true&data=" + resp.data.data);
          } else {
            that.$message({
              showClose: true,
              message: resp.data.msg,
              type: "error",
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.el-pagination {
  white-space: normal !important;
}
li {
  list-style: none;
}
.showArcitle li {
  transition: all 0s;
  padding: 20px 10px 0 10px;
  border: 1px solid #f6efed;
  background: white;
  border-radius: 15px;
  margin: 20px 0;
}

.showArcitle li:hover {
  cursor: pointer;
  box-shadow: rgb(77 77 77 / 17%) 0px 0px 20px 10px;
}
</style>